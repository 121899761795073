import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import hexToRgba from "hex-to-rgba";
import moment from "moment";
import { GlobalWorkerOptions } from "pdfjs-dist";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate , useSearchParams} from "react-router-dom";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { getChartRecords } from "../../api/records.api";
import { SnackBar } from "../../components/shared";
import {
  getRecordTypeAction,
  getRecordsAction,
  getThumbnails,
  getReportURL,
} from "../../store/actions";
import { recordTypeSelector } from "../../store/slice/record-type.slice";
import GraphicalRecord from "./graphical-record";
import { INextArrow } from "../../assets";
import {
  CardsContainer,
  Container,
  EmptyListMessage,
  Header,
  HeaderWrapper,
  ListDateTitle,
  ListItemDate,
  ListItemWrapper,
  ListSection,
  ListWrapper,
  LoaderWrapper,
  StyledCloseButton,
  StyledImage,
  StyledModal,
  StyledModalContent,
  ListItemTop,
  SeeMoreWrapper,
  SeeMoreText,
  NextArrow,
  ListSectionQuestionnaire,
  ListDateTitlQuestionnaire,
} from "./style.components";
import Breadcrumb from "../../components/Breadcrumb";
import RecordItem from "../../components/layout/RecordItem/RecordItem";
import NoteRecordItem from "../../components/layout/NoteRecordItem/NoteRecordItem";
import { Grid, Box } from "@mui/material";
import { resetState } from "../../store/slice/records.slice";
import { resetThumbnail } from "../../store/slice/thumbnail.slice";
const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const breadCrubLinks = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Health Data",
  },
];

const OBSERVATION_RECORDS = [
  "blood_pressure",
  "blood_sugar",
  "pain_level",
  "temperature",
  "weight",
];

const PatientRecords = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedPatientId = searchParams.get("patient_id");
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordTypesArray, setRecordTypesArray] = useState([]);
  const recordTypes = useSelector((state) => recordTypeSelector(state));
  const [observationRecordTypes, setObservationRecordTypes] = useState([]);
  const [observationData, setObservationData] = useState({});
  const [filledFormsData, setFilledFormsData] = useState([]);
  const navigate = useNavigate();
  const [itemsToShow, setItemsToShow] = useState(
    getItemsToShow(window.innerWidth)
  );
  const screenWidth = window.innerWidth;
  const isMobileWidth = screenWidth <= 768;
  const [overallLoading, setOverallLoading] = useState(true);
  const accordionData = useSelector((state) => state?.record?.records);
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);
  const [isloadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const [tempAccordionData, setTempAccrodionData] = useState({});
  const [noteItemToShow, setNoteItemShow] = useState(getItemsNoteToShow(window.innerWidth))

  function getItemsToShow(width) {
    if (width >= 1050) {
      return 5;
    } else if (width >= 1005) {
      return 3;
    } else if (width >= 850) {
      return 2;
    } else if (width >= 767) {
      return 1;
    } else if (width >= 650) {
      return 3;
    } else if (width >= 506) {
      return 2;
    } else {
      return 1;
    }
  }

  function getItemsNoteToShow(width) {
    if (width >= 1300) {
      return 4;
    }else if (width >= 1050) {
      return 3;
    } else if (width >= 1005) {
      return 2;
    } else if (width >= 850) {
      return 1;
     } else if (width >= 650) {
      return 2;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    function handleResize() {
      const newItemsToShow = getItemsToShow(window.innerWidth);
      const newItemsNoteToShow = getItemsNoteToShow(window.innerWidth);
      if (newItemsToShow !== itemsToShow) {
        setItemsToShow(newItemsToShow);
        setNoteItemShow(newItemsNoteToShow);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsToShow]);

  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await dispatch(getReportURL({ payload: payload }));
      return response?.payload?.data;
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  useEffect(() => {
    if(!recordTypes?.length)
      dispatch(getRecordTypeAction(selectedPatientId));
  }, [selectedPatientId, dispatch]);

  useEffect(() => {
    const fetchAllRecords = async (isPageRefresh = false) => {
      const fetchPromises = recordTypesArray.map(({ record_type }) => {
        if (record_type !== "questionnaire") fetchRecords(record_type, isPageRefresh);
      });

      try {
        await Promise.all(fetchPromises);
      } catch (error) {
        console.error("Error fetching one or more records:", error);
      }
    };
    if (recordTypesArray.length) {
      if (
        accordionData &&
        Object.keys(accordionData)?.length &&
        selectedPatientId !== accordionData.patient_id
      ) {
        dispatch(resetState());
        dispatch(resetThumbnail());
        fetchAllRecords(false);
      } else if (
        accordionData &&
        Object.keys(accordionData)?.length &&
        selectedPatientId === accordionData.patient_id
      ) {
        fetchAllRecords(true);
      } else {
        fetchAllRecords(false);
      }
    }
  }, [recordTypesArray]);

  useEffect(() => {
    if (recordTypes && recordTypes?.length) {
      const excludeRecordTypes = [
        "blood_pressure",
        "blood_sugar",
        "pain_level",
        "temperature",
        "weight",
      ];

      const updatedRecordTypes = recordTypes
        .filter((record) => !excludeRecordTypes.includes(record.record_type))
        .map((record) => {
          return record;
        });
      const updatedObsRecordTypes = recordTypes
        .filter((record) => OBSERVATION_RECORDS.includes(record.record_type))
        .map((record) => {
          return record;
        });
      setRecordTypesArray(updatedRecordTypes);
      setObservationRecordTypes(updatedObsRecordTypes);
    }
  }, [recordTypes]);

  const renderQuestionnaireSection = () => {
    const convertedData = accordionData && accordionData["questionnaire"]?.data?.length && accordionData["questionnaire"].data.flatMap(item => 
      item.questionnaire_response_ids.map(response => ({
        ...response,
        parent_id: item.id,
        name: item.title,
        upload_date: response.last_updated
      }))
    );
    return (
      <ListWrapper>
        <ListSectionQuestionnaire>
          <ListDateTitlQuestionnaire>Questionnaire {convertedData?.length ? `(${convertedData.length})` : null}</ListDateTitlQuestionnaire>
        </ListSectionQuestionnaire>
        <Grid container gap={2} mt={4}>
          {accordionData && accordionData["questionnaire"]?.data?.length && convertedData
                ?.slice(0, itemsToShow)
            .map((row, formIndex) => (
              <RecordItem
                row={row}
                fetchUrlForFile={() => {
                  const rowWithRecordType = {
                    ...row,
                    record_type: "questionnaire",
                  };
                  handleListItemClick(rowWithRecordType);
                }}
                menuItems={[]}
                reportType = {"questionnaire"}
                isShowDropdown ={false}
                index ={formIndex}
              />

            )
            )
            .concat(
              accordionData["questionnaire"]?.total > itemsToShow && (
                <ListItemWrapper key="see-more">
                  <ListItemTop style={{ visibility: "hidden" }}>
                    hidden
                  </ListItemTop>
                  <SeeMoreWrapper
                    onClick={() => navigate("questionnaire-list")}
                  >
                    <SeeMoreText>See More</SeeMoreText>
                    <NextArrow src={INextArrow} />
                  </SeeMoreWrapper>
                  <ListItemDate style={{ visibility: "hidden" }}>
                    hidden
                  </ListItemDate>
                  <ListItemDate style={{ visibility: "hidden" }}>
                    hidden
                  </ListItemDate>
                </ListItemWrapper>
              )
            )}
        </Grid>
      </ListWrapper>
    );
  };

  const initialState = recordTypesArray?.reduce((acc, { record_type }) => {
    acc[record_type] = "loading";
    return acc;
  }, {});
  const [loadedAccordions, setLoadedAccordions] = useState(initialState);

  useEffect(() => {
    const isAnyLoading = Object.keys(loadedAccordions).length
      ? Object.values(loadedAccordions).some((status) => status === "loading")
      : true;

    setOverallLoading(isAnyLoading);
  }, [loadedAccordions]);

  const fetchRecords = (recordType, isPageRefresh=false) => {
    return new Promise(async (resolve, reject) => {
      if (loadedAccordions[recordType]) return resolve();
       !isPageRefresh && setLoadedAccordions((prev) => ({ ...prev, [recordType]: "loading" }));
      if (selectedPatientId) {
        try {
          const action = await dispatch(
            getRecordsAction({
              record_type: recordType,
              patient_id: selectedPatientId,
              limit: 5,
            })
          );
          if(action?.payload?.data) {
            setTempAccrodionData((prev) => ({ ...prev, [recordType]: action?.payload?.data }));
          }
          setLoadedAccordions((prev) => ({ ...prev, [recordType]: "loaded" }));
          resolve();
        } catch (error) {
          console.error("Error fetching records:", error);
          setLoadedAccordions((prev) => ({ ...prev, [recordType]: "error" }));
          reject(error);
        }
      } else {
        resolve();
      }
    });
  };

  const extractIdAndFilePath = () => {
    const result = [];
    Object.keys(tempAccordionData)?.forEach((category) => {
      if (category === "scans" || category === "patient_id" || category === "questionnaire") return;
      tempAccordionData[category]?.forEach((item) => {
        if (item.id && item.file_path) {
          result.push({
            id: item.id,
            file_path: item.file_path,
            record_type: category,
          });
        }
      });
    });
    return result;
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      fetchThumbnailImages(true);
    }, 300000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const fetchThumbnailImages = async (isResetThumbnail=false) => {
    const data = extractIdAndFilePath();
    const filePaths = data
    .filter((item) => item?.file_path && (isResetThumbnail || !thumbnails?.[item.id]))
    .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length && dispatch(
      getThumbnails({
        file_objects: filePaths,
      })).then(() => {
        setIsLoadingThumbnail(!isloadingThumbnail);
      });
  };
  useEffect(() => {
    if (tempAccordionData && (Object.keys(tempAccordionData).length === recordTypesArray.length - 1)) {
      fetchThumbnailImages(true);
    }
  }, [tempAccordionData]);   

  const showEmptyMessage =  accordionData &&
  Object.keys(accordionData)
  .filter(key => key !== 'patient_id') 
  .every(key => Array.isArray(accordionData[key].data) && accordionData[key].data.length === 0);

  const handleListItemClick = async (record) => {
    if (record?.record_type === "scans") {
      const url = isMobileWidth
        ? record?.mobile_viewer_url
        : record?.dicom_viewer_url;
      const newWindow = window.open("", "_blank");
      if (newWindow) newWindow.location.href = url;
    } else if (record?.name === "Notes") {
      return;
    } else if (
      record?.record_type !== "scans" ||
      record?.record_type !== "notes"
    ) {
      const url = await fetchReportUrl(record?.file_path);
      setSelectedRecord({ ...record, url: url });
      setOpen(true);
    }
  };

  const closeModal = () => {};

  const getObservationRecords = useCallback(
    async (r) => {
      const response = await getChartRecords(r, selectedPatientId);
      const result = response?.result;
      let dataset = [];
      result?.datasets?.forEach((item) => {
        let data = [];
        item?.data?.forEach((values) => {
          data.push({
            y: values?.value,
            x: moment(values.timestamp).format("YYYY-MM-DD HH:mm"),
          });
        });
        dataset.push({
          label: item.label,
          borderColor: item.color,
          data,
          backgroundColor: hexToRgba(item.color, "0.5"),
        });
      });
      observationData[r] = dataset;
      setObservationData(observationData);
    },
    [observationData, selectedPatientId]
  );

  const handleScanClick = (fhirId) => {
    navigate("scan-info", {
      state: {
        studyFhirId: fhirId,
      },
    });
  };

  useEffect(() => {
    if (observationRecordTypes?.length > 0) {
      observationRecordTypes.forEach(({ record_type }) => {
        getObservationRecords(record_type);
      });
    }
  }, [observationRecordTypes, getObservationRecords]);

  const renderListSection = (recordType, name, data) => {
    return (
      <ListSection>
        <ListDateTitle>
          {name}{" "}
          {accordionData[recordType]?.total
            ? `(${accordionData[recordType].total})`
            : ""}
        </ListDateTitle>
      </ListSection>
    );
  };

  const renderGraphList = (recordType, name, data) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        key={recordType}
      >
        <ListWrapper>
          {renderListSection(recordType, name, data, false)}
          <GraphicalRecord graphData={data?.[recordType]} />
        </ListWrapper>
      </div>
    );
  };

  const renderSection = (record_type, name, index) => {
    // Example rendering logic for a section
    // Adjust this to match how you render each section of your application
    switch (record_type) {
      case "scans":
        return (
          <ListWrapper key={index}>
            {renderListSection(record_type, name)}
            <Grid container mt={3} gap={2}>
              {accordionData[record_type]?.data?.length > 0 &&
                accordionData[record_type].data
                  ?.slice(0, itemsToShow)
                  ?.map((row, index) => (
                    <RecordItem
                      reportType={"scan"}
                      fetchUrlForFile={() => handleScanClick(row?.id)}
                      isShowDropdown={false}
                      row={row}
                      index={index}
                    />
                  ))
                  .concat(
                    accordionData[record_type]?.total > itemsToShow && (
                      <ListItemWrapper key="see-more">
                        <ListItemTop style={{ visibility: "hidden" }}>
                          hidden
                        </ListItemTop>
                        <SeeMoreWrapper
                          onClick={() => {
                            navigate("all-records", {
                              state: {
                                recordType: record_type,
                                recordName: name,
                                patientID: selectedPatientId,
                              },
                            });
                          }}
                        >
                          <SeeMoreText>See More</SeeMoreText>
                          <NextArrow src={INextArrow} />
                        </SeeMoreWrapper>
                      </ListItemWrapper>
                    )
                  )}
            </Grid>
          </ListWrapper>
        );
      case "notes": 
        return (
          <ListWrapper>
            {renderListSection(record_type, name)}
            <Grid container gap={2} mt={4}>
              {accordionData[record_type]?.data?.length > 0 &&
                accordionData[record_type]?.data
                  ?.slice(0, noteItemToShow)
                  ?.map((row, index) => (
                    <NoteRecordItem row={row} index={index} />
                    ))
                  .concat(
                    accordionData[record_type]?.total > itemsToShow && (
                      <ListItemWrapper key="see-more">
                        <Box
                          onClick={() => {
                            navigate("all-records", {
                              state: {
                                recordType: record_type,
                                recordName: name,
                                patientID: selectedPatientId,
                              },
                            });
                          }}
                          sx={{
                            width: 180,
                            height: 260,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            textAlign: "center",
                            background: "#ebf7fb",
                          }}
                        >
                          <SeeMoreText>See More</SeeMoreText>
                          <NextArrow src={INextArrow} />
                        </Box>
                      </ListItemWrapper>
                    )
                  )}
            </Grid>
          </ListWrapper>
        );
      default:
        return (
          <ListWrapper key={index}>
            {renderListSection(record_type, name)}
            <Grid mt={3} gap={2} container>
              {accordionData[record_type]?.data?.length > 0
                ? accordionData[record_type].data
                    ?.slice(0, itemsToShow)
                    ?.map((row, index) => (
                      <RecordItem
                        reportType={record_type}
                        isShowDropdown={false}
                        fetchUrlForFile={() => handleListItemClick(row)}
                        row={row}
                        index={index}
                        thumbnailData={thumbnails}
                      />
                    ))
                    .concat(
                      accordionData[record_type]?.total > itemsToShow && (
                        // Additional ListItemWrapper for the "See More" functionality
                        <ListItemWrapper key="see-more">
                          <ListItemTop style={{ visibility: "hidden" }}>
                            hidden
                          </ListItemTop>
                          <SeeMoreWrapper
                            onClick={() => {
                              navigate("all-records", {
                                state: {
                                  recordType: record_type,
                                  recordName: name,
                                  patientID: selectedPatientId,
                                },
                              });
                            }}
                          >
                            <SeeMoreText>See More</SeeMoreText>
                            <NextArrow src={INextArrow} />
                          </SeeMoreWrapper>

                        </ListItemWrapper>
                      )
                    )
                : null}
            </Grid>
          </ListWrapper>
        );
    }
  };

  const renderSectionsWithLoadingIndicator = () => {
    const sortedRecordTypes = recordTypesArray.sort((a, b) => {
      return a.id - b.id;
    });

    const questionnaireDataLoaded =
     tempAccordionData && accordionData && accordionData["questionnaire"]?.data?.length > 0;

    return (
      <>
        {/* Explicitly render questionnaire section if data is loaded */}
        {/* {questionnaireDataLoaded && renderQuestionnaireSection()} */}

        {accordionData &&
          sortedRecordTypes.map(({ record_type, name }) => {
            // Skip rendering questionnaire section here since it's handled separately
            // if (record_type === 'questionnaire') return null;

            const sectionData = accordionData[record_type]?.data;
            const isLoading = loadedAccordions[record_type] === "loading";
            const hasData = sectionData && sectionData?.length > 0;
            if (record_type === "questionnaire") return null;
            return (
              <React.Fragment key={record_type}>
                {!isLoading && hasData && (
                  
                  <div>{renderSection(record_type, name)}</div>
                )}
              </React.Fragment>
            );
          })}
        {/* {questionnaireDataLoaded && renderQuestionnaireSection()} */}
        {overallLoading && (
          <LoaderWrapper>
            <Container>
              <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={82}
                thumbnailHeight={82}
              />
            </Container>
          </LoaderWrapper>
        )}
      </>
    );
  };

  return (
    <Container>
      <Breadcrumb links={breadCrubLinks} />
      <>
        <HeaderWrapper>
          <Header>Health Data</Header>
        </HeaderWrapper>
        {showEmptyMessage && !overallLoading ? (
          <EmptyListMessage>No records found</EmptyListMessage>
        ) : (
          <>
            {renderSectionsWithLoadingIndicator()}
            {observationRecordTypes
              ?.filter(
                ({ record_type }) => observationData[record_type]?.length > 0
              )
              .map(({ record_type, name }) =>
                renderGraphList(record_type, name, observationData)
              )}
          </>
        )}
      </>
      <StyledModal open={open} onClose={closeModal}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {selectedRecord &&
            (["image/jpeg", "image/png"].includes(
              selectedRecord.content_type
            ) || selectedRecord?.record_type === "image" ? (
              <StyledImage src={selectedRecord.url} alt={selectedRecord.name} />
            ) : (
              <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={selectedRecord.url} />
              </Worker>
            ))}
        </StyledModalContent>
      </StyledModal>
      <SnackBar />
    </Container>
  );
};

export default PatientRecords;
