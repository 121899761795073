import moment from "moment";
import { Line } from "react-chartjs-2";

export const options = {
  responsive: true,
  scales: {
    x: {
      type: "time",
      time: {
        unit: "hours",
      },
      ticks: {
        callback: function (label) {
          return moment(label).format("DD/MM/YY hh:mm");
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const GraphicalRecord = ({ graphData }) => {
  if (!graphData || graphData?.length <= 0) return;
  return <Line options={options} data={{ datasets: graphData }} />;
};

export default GraphicalRecord;
