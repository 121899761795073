export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
// export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_KEY = "12345"; // staging
// export const API_KEY = "fc94735af57f22eb" // prod
export const API_LOGIN_URL = `/users`;

export const FONT_FAMILY_GENERAL = "Noto Sans Display";
export const COLOR_PRIMARY = "#0099CC";
export const COLOR_SECONDARY = "#FFF";
export const COLOR_VARIANT = "#7ABA57";
export const COLOR_GREY = "rgb(128, 128, 128)";
export const COLOR_ERROR = "#ff0033";
export const COLOR_PRIMARY_LIGHT = "#F5FBFC";
export const COLOR_LINK = "rgb(0, 153, 204)";
export const TEXT_COLOR_PRIMARY = "#000";
export const HEADER_HEIGHT = 71; // 72 but 1 is removed as there is a border to this header
export const FOOTER_HEIGHT = "28px";
export const SIDENAV_WIDTH = "20%";
// export const API_URL = "https://staging.api.hemehealth.app/app"; //staging
// export const API_URL = "https://dev.hemehealth.app/app/v1/shared-view/patients/type/scans"
export const API_URL = "https://api.hemehealth.app/app"; //prod
// export const MEDIA_URL = "https://staging.api.hemehealth.app"; // staging media
export const MEDIA_URL = "https://api.hemehealth.app"; // prod media
export const COOKIE_EXPIRY_DAYS = 1826;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;

export const API_ENDPOINT = {
  GENERATE_OTP: `${API_URL}/v1/auth/otp/generate`,
  VERIFY_OTP: `${API_URL}/v1/auth/otp/verify`,
  VERIFY_OTP_VALIDATE: `${API_URL}/v1/auth/otp/validate`,
  GET_ACCESS_TOKEN: `${API_URL}/v1/auth/otp/access`,
  LOGOUT: `${API_URL}/v1/auth/logout`,
  PATIENTS: `${API_URL}/v1/shared-view/patients`,
  PATIENT: `${API_URL}/patient`,
  UPLOAD_AUDIO: `${API_URL}/audio/upload`,
  START_TRANSCRIPT: `${API_URL}/audio/summary`,
  START_OPENAI_TRANSCRIPT: `${API_URL}/audio/whisper/summary`,
  MEDIA_UPLOAD: `${MEDIA_URL}/media/v1/upload`,
  SEND_RECORDS: `${API_URL}/records/add`,
  RECORD_TYPES: `${API_URL}/v1/shared-view/patients/type`,
  GET_RECORDS: `${API_URL}/v1/patient/health-data/type`,
  GET_Charts: `${API_URL}/v1/shared-view/patients/type`,
  GET_STUDY_LIST: `${API_URL}/v1/shared-view/patients/type/scans`,
  GET_SUPPORTED_COUNTRIES: `${API_URL}/v1/supported_countries`,
  VERFYCONSENT:`${API_URL}/v1/shared-view/scan/consent`,
  GET_STUDY_DETAIL:`${API_URL}/v1/shared-view/list_data/study`,
  GET_THUMBNAIL: `${API_URL}/v1/patient/health-data/signed-thumbnail`,
  GET_RECORD_URL: `${API_URL}/v1/patient/health-data/file`,
  GET_SCANS: `${API_URL}/v1/list_data/study`,
  GET_VIEWER_URLS: `${API_URL}/v1/list_data/viewer`,
  GET_ATTACHED_REPORT :`${API_URL}/v1/shared-view/scans`,
};

export const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1023px",
  laptopL: "1439px",
  desktop: "2560px",
};

export const DEVICE = {
  mobileS: `(max-width: ${SIZE.mobileS})`,
  mobileM: `(max-width: ${SIZE.mobileM})`,
  mobileL: `(max-width: ${SIZE.mobileL})`,
  tablet: `(max-width: ${SIZE.tablet})`,
  laptop: `(max-width: ${SIZE.laptop})`,
  laptopL: `(max-width: ${SIZE.laptopL})`,
  desktop: `(max-width: ${SIZE.desktop})`,
  desktopL: `(max-width: ${SIZE.desktop})`,
};
