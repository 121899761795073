import { Box, Tooltip } from "@mui/material";
import styled from "styled-components";

const InfoName = styled.div`
  font-weight: 600;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 25ch;
`;
const HeaderTitle = {
  color: { xs: "#808080", sm: "#000" },
};

const ExtraStudyInfo = ({ recordInfo, scans }) => {
  const uniqueModality = Array.from(
    new Set(scans?.map((item) => item.modality_code))
  );
  return (
    <>
      <Box mt={1}>
        <Box sx={HeaderTitle}>UID</Box>
        <Tooltip title={recordInfo.study_id} arrow>
          <InfoName>{recordInfo.study_id}</InfoName>
        </Tooltip>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Box>
          <Box sx={HeaderTitle}>Modality</Box>
          <InfoName>{uniqueModality.join(",") || "-"}</InfoName>
        </Box>
        <Box>
          <Box sx={HeaderTitle}>Series</Box>
          <InfoName>{scans?.length || "-"}</InfoName>
        </Box>
      </Box>
      <Box mt={1}>
        <Box sx={HeaderTitle}>Description</Box>
        <InfoName>{recordInfo.description || "-"}</InfoName>
      </Box>
    </>
  );
};

export default ExtraStudyInfo;
