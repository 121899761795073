import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const DropdwonThreeDotMenu = ({
  menuItems = [],
  data,
  setStoreData = (e) => {},
  recordType,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setStoreData({ ...data, record_type: recordType });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const menuClick = (event, itemClick) => {
    event.stopPropagation();
    itemClick();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="dynamic-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: "#0099CC", cursor: "pointer", padding: "2px" }}
        disabled={!Boolean(menuItems.length)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="dynamic-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={`dropdown-${index}`}
            onClick={(e) => menuClick(e, item.onClick)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdwonThreeDotMenu;
