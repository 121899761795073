import { CircularProgress, Box, Typography, Link } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ILogoHorizontalWhite, IResend } from "../../assets";
import EmailIcon from "../../assets/mail-icon.svg";
import MobileIcon from "../../assets/mobile-icon.svg";
import BlackRightArrow from "../../assets/black-right-arrow-icon.svg";
import IBackArrow from "../../assets/back-arrow-blue.svg";
import ComplianceRoot from "../compliance/index";
import { IconButton } from "@mui/material";
import { generateOTPAction } from "../../store/actions";
import { COLOR_VARIANT } from "../../utilities/constants";
import { SnackBar } from "../../components/shared";
import {
  getSupportedCountriesAction,
  verifyOTPAction,
  getVerifyAccessToken,
} from "../../store/actions/auth.action";
import { authActions, authSelector } from "../../store/slice/auth.slice";
import {
  ActionText,
  CustomButton,
  CustomInput,
  CustomSelect,
  FormFieldRow,
  FormFieldSection,
  FormLabel,
  FormMessage,
  LoginContainer,
  LoginForm,
  LoginFormRight,
  LoginLogo,
  TCLink,
  TCText,
  BackButtonContainer,
  BackArrowImg,
  BackText,
  LoginByText,
  InfoContainer,
  IconTextContainer,
  MobileIconImg,
  EmailIconImg,
  BlackRightArrowImg,
} from "./style.components";
import { unsetShowStatus } from "../../store/slice/status-slice";
import { MainLoginIcon } from "../../assets";

const Login = () => {
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [email, setEmail] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isSentOtp, setIsSentOtp] = useState(false);

  const [searchParams] = useSearchParams();
  const [selectedSupportedCountry, setSelectedSupportedCountry] =
    useState(null);
  const [type, setType] = useState("");

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showComplianceRoot, setShowComplianceRoot] = useState(false);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [validationToken, setValidationToken] = useState("");
  const [supportedCountriesData, setSupportedCountriesData] = useState([]);

  const navigate = useNavigate();

  const {
    isLoading,
    isOTPVerified,
    userDetails,
    selectedCountry,
    supportedCountries,
  } = useSelector(authSelector);

  useEffect(() => {
    if (isOTPVerified && userDetails?.access_token) {
      let accessCode = searchParams.get("accessCode");
      navigate(`/home${accessCode ? "?accessCode=" + accessCode : ""}`);
    }
  }, [isOTPVerified, userDetails, navigate]);

  const handleSendOtpClick = () => {
    sendOtpToMobileNumber();
  };

  const handleLoginClick = () => {
    validateOtp();
  };

  const handleLoginTypeSelection = (selectedType) => {
    setType(selectedType);
    if (selectedType == "phone") {
      setShowLoginForm(true);
      setShowComplianceRoot(false);
    } else {
      setShowLoginForm(true);
      setShowComplianceRoot(false);
    }
  };

  const handleContrySlection = (data) => {
    setIsCountrySelected(data.code);
    setTimeout(() => {
      getToken(data.code, validationToken);
    }, 1000);
  };
  const handleBackToLoginTypeSelection = () => {
    // removeCookie("countryData");
    // setSelectedCountry("");
    dispatch(authActions.selectedCountry(""));
    setShowLoginForm(false);
    setShowComplianceRoot(false);
    setType("");
    setEmail("");
    setMobileNumber("");
    setOtp("");
    setOtpEmail("");
    setIsSentOtp(false);
  };

  const sendOtpToMobileNumber = async () => {
    if (!mobileNumber && !email) {
      return;
    }

    let payload = {};
    if (mobileNumber && type === "phone") {
      payload = {
        auth_id: countryCode + "" + mobileNumber,
        auth_type: "phone",
        source: "login",
      };
    } else if (email && type === "email") {
      payload = {
        auth_id: `${email}`,
        auth_type: "email",
        source: "login",
      };
    }

    const res = await dispatch(generateOTPAction(payload));
    console.log("resresresres", res);
    if (res.payload.status === "success") {
      setIsSentOtp(true);
    }
  };
  const supportedCountyListData = [
    {
      code: "IN",
      display_name: "India",
      icon: "https://heme-content-files.s3.ap-south-1.amazonaws.com/flags/india.png",
    },
    {
      code: "GB",
      display_name: "United Kingdom",
      icon: "https://heme-content-files.s3.ap-south-1.amazonaws.com/flags/uk.png",
    },
    {
      code: "IN",
      display_name: "Others",
      icon: "https://heme-content-files.s3.ap-south-1.amazonaws.com/flags/globe.png",
    },
  ];

  const validateOtp = async () => {
    // const payload = {
    //   auth_id: `${countryCode}${mobileNumber}`,
    //   auth_type: "phone",
    //   source: "login",
    //   otp: otp,
    //   country: selectedCountry?.code || selectedSupportedCountry?.code,
    // };
    let payload =
      type === "phone"
        ? {
            auth_id: `${countryCode}${mobileNumber}`,
            auth_type: "phone",
            source: "login",
            otp: otp,
            platform_type: "share_view_portal"
          }
        : {
            auth_id: email,
            auth_type: "email",
            source: "login",
            otp: otpEmail,
            platform_type: "share_view_portal"
          };

    const res = await dispatch(verifyOTPAction(payload));

    if (res?.payload?.result?.datastores?.length > 1) {
      dispatch(unsetShowStatus());
      setShowComplianceRoot(true);
      setShowLoginForm(false);
      setIsCountrySelected(false);
      setValidationToken(res?.payload?.result?.validation_token);
      setSupportedCountriesData(res?.payload?.result?.datastores);
    } else {
      if (res?.payload?.result?.datastores?.length == 0) {
        dispatch(unsetShowStatus());
        setShowComplianceRoot(true);
        setShowLoginForm(false);
        setIsCountrySelected(false);
        setValidationToken(res?.payload?.result?.validation_token);
        setSupportedCountriesData(supportedCountyListData);
      } else {
        await dispatch(
          authActions.selectedCountry(res?.payload?.result?.datastores[0])
        );
        await getToken(
          res?.payload?.result?.datastores[0]?.code,
          res?.payload?.result?.validation_token
        );
      }
    }
  };

  const getToken = async (code, validationToken) => {
    console.log("codeê", code);
    const countryData = {
      country: code,
      validationToken,
    };
    await dispatch(getVerifyAccessToken(countryData));
  };

  const fetchCountries = useCallback(async () => {
    await dispatch(getSupportedCountriesAction());
  }, [dispatch]);

  useEffect(() => {
    const phoneNumber = searchParams?.get("phone")?.trim();
    const country = searchParams?.get("country")?.trim();
    const email = searchParams?.get("email")?.trim();
    let inputNumber = "";
    if (phoneNumber) {
      if (["91", "44"].includes(phoneNumber.slice(0, 2).toString())) {
        const countryCode = phoneNumber.slice(0, 2).toString();
        setCountryCode(`+${countryCode}`);
        inputNumber = phoneNumber.slice(2, phoneNumber.length);
      } else if (["212"].includes(phoneNumber.slice(0, 3))) {
        setCountryCode(`+${phoneNumber.slice(0, 3).toString()}`);
        inputNumber = phoneNumber.slice(3, phoneNumber.length);
      }
      setMobileNumber(inputNumber);
      const _selectedSupportedCountry = supportedCountries?.filter(
        (c) => c?.code === country
      )?.[0];
      setSelectedSupportedCountry(_selectedSupportedCountry);
      dispatch(authActions.selectedCountry(_selectedSupportedCountry));
      handleLoginTypeSelection("phone")
    }else if(email) {
      setEmail(email);
      handleLoginTypeSelection("email");
      const _selectedSupportedCountry = supportedCountries?.filter(
        (c) => c?.code === country
      )?.[0];
      setSelectedSupportedCountry(_selectedSupportedCountry);
      dispatch(authActions.selectedCountry(_selectedSupportedCountry));
    }else {
        handleLoginTypeSelection("phone");
    }
  }, [searchParams, supportedCountries, dispatch]);

  useEffect(() => {
    const phoneNumber = searchParams?.get("phone")?.trim();
    if (selectedCountry?.code && !phoneNumber) {
      let code;
      switch (selectedCountry?.code) {
        case "IN": {
          code = "+91";
          break;
        }
        case "GB": {
          code = "+44";
          break;
        }
        default:
          code = "+91";
      }
      setCountryCode(code);
    }
  }, [selectedCountry]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const renderImg = () => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb={2}>
        <Typography
          sx={{
            fontSize: "26px",
            "@media (max-width:900px)": {
              fontSize: "18px",
              marginBottom: "5px"
            },
            color: "#FFF",
            fontWeight: 600,
            textAlign: "center",
            marginBottom: 1,
          }}
        >
          Secure patient data access
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "16px",
            "@media (max-width:900px)": {
              fontSize: "12px",
            },
          }}
        >
          Login for secure access
        </Typography>
      </Box>
      <Box
        component="img"
        src={MainLoginIcon}
        alt="login-img"
        sx={{
          maxWidth: "100%",
          height: "auto",
          width: { xs: "300px", md: "600px" },
          margin: { xs: 0, md: 2 },
        }}
        loading="lazy"
      />
      <Typography
        sx={{
          textAlign: "center",
          color: "#FFF",
          fontSize: "16px",
          "@media (max-width:900px)": {
            fontSize: "12px",
          },
        }}
      >
        Visit us at{" "}
        <Link
          href="https://www.hemehealth.io"
          underline="always"
          sx={{ color: "#FFF", textDecorationColor: "#FFF" }}
        >
          www.hemehealth.io{" "}
        </Link>
      </Typography>
    </Box>
  );

  return (
    <>
      <LoginContainer>
        <LoginLogo>
          <img src={ILogoHorizontalWhite} alt="logo" />
        </LoginLogo>
        {showLoginForm ? (
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            justifyContent="center"
            m={1}
          >
            {renderImg()}
            <LoginFormRight>
              <BackButtonContainer onClick={handleBackToLoginTypeSelection}>
                <BackArrowImg src={IBackArrow} />
                <BackText>{`Back`}</BackText>
              </BackButtonContainer>
              {/* <FormMessage>
                  {selectedCountry?.icon || selectedSupportedCountry?.icon ? (
                    <img
                      src={
                        selectedCountry?.icon || selectedSupportedCountry?.icon
                      }
                      alt="selected_country"
                      style={{ height: 20, marginLeft: 10 }}
                    />
                  ) : (
                    ""
                  )}
                  <div style={{ marginLeft: "20px" }}>
                    {isLoading ? (
                      <CircularProgress
                        size={18}
                        style={{ color: COLOR_VARIANT }}
                      />
                    ) : null}
                  </div>
                </FormMessage> */}
              {!isSentOtp ? (
                <>
                  <FormFieldSection>
                    <FormLabel>
                      {type === "phone" ? "Mobile Number" : "E-mail"}
                    </FormLabel>
                    <FormFieldRow>
                      {type === "phone" && (
                        <>
                          <CustomSelect
                            value={countryCode}
                            onChange={(event) =>
                              setCountryCode(event.target.value)
                            }
                          >
                            <option value="+91">+91</option>
                            <option value="+44">+44</option>
                            <option value="+212">+212</option>
                          </CustomSelect>
                          <CustomInput
                            value={mobileNumber}
                            onChange={(event) =>
                              setMobileNumber(event.target.value)
                            }
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                          ></CustomInput>
                        </>
                      )}

                      {type === "email" && (
                        <CustomInput
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                        />
                      )}

                      {/* <ActionText onClick={handleSendOtpClick}>Send OTP</ActionText> */}
                    </FormFieldRow>
                    <CustomButton
                      onClick={handleSendOtpClick}
                      value="Send OTP"
                      type="button"
                      style={{ margin: "40px auto" }}
                    />
                  </FormFieldSection>
                </>
              ) : (
                <>
                  <FormFieldSection>
                    <FormLabel>Enter OTP</FormLabel>
                    <FormFieldRow>
                      <CustomInput
                        // value={otp}
                        value={type === "phone" ? otp : otpEmail}
                        onChange={(event) =>
                          type === "phone"
                            ? setOtp(event.target.value)
                            : setOtpEmail(event.target.value)
                        }
                        type={"number"}
                      ></CustomInput>
                      <ActionText onClick={handleSendOtpClick}>
                        {" "}
                        <img
                          src={IResend}
                          style={{ marginRight: "4px" }}
                          alt="resend"
                        />{" "}
                        Resend
                      </ActionText>
                    </FormFieldRow>
                  </FormFieldSection>
                  <TCText>
                    By logging in you agree to our
                    <TCLink href="terms-conditions" target="_blank">
                      Terms and Conditions
                    </TCLink>
                  </TCText>
                  <FormFieldSection>
                    <CustomButton
                      onClick={handleLoginClick}
                      value="Login"
                      type="button"
                    />
                  </FormFieldSection>
                </>
              )}
            </LoginFormRight>
          </Box>
        ) : (
          <>
            {showComplianceRoot && (
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
                justifyContent="center"
                m={1}
              >
                {renderImg()}
                <LoginFormRight>
                  {/* <VerticalSep size={30}></VerticalSep> */}
                  <BackButtonContainer onClick={handleBackToLoginTypeSelection}>
                    <BackArrowImg src={IBackArrow} />
                    <BackText>{`Back to Login`}</BackText>
                  </BackButtonContainer>
                  <FormFieldSection>
                    <FormLabel>
                      {"Select country in which you want to access"}
                    </FormLabel>
                    <ComplianceRoot
                      handleContrySlection={handleContrySlection}
                      supportedCountriesData={supportedCountriesData}
                    />

                    {/* <VerticalSep size={10}></VerticalSep> */}
                  </FormFieldSection>
                </LoginFormRight>
              </Box>
            )}

            {!showComplianceRoot && (
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
                justifyContent="center"
                m={1}
              >
                {renderImg()}
                <LoginFormRight>
                  <LoginByText>Login by</LoginByText>
                  <InfoContainer
                    onClick={() => handleLoginTypeSelection("phone")}
                  >
                    <IconTextContainer>
                      <Box
                        component="img"
                        src={MobileIcon}
                        alt="mobile-icon"
                        sx={{ width: "24px", height: "24px" }}
                      />
                      <span>Mobile Number</span>
                    </IconTextContainer>
                    <IconButton>
                      <Box
                        component="img"
                        src={BlackRightArrow}
                        alt="right-arrow"
                      />
                    </IconButton>
                  </InfoContainer>
                  <InfoContainer
                    onClick={() => handleLoginTypeSelection("email")}
                  >
                    <IconTextContainer>
                      <Box
                        component="img"
                        src={EmailIcon}
                        alt="email-icon"
                        sx={{ width: "24px", height: "24px" }}
                      />
                      <span>E-mail</span>
                    </IconTextContainer>
                    <IconButton>
                      <Box
                        component="img"
                        src={BlackRightArrow}
                        alt="right-arrow"
                      />
                    </IconButton>
                  </InfoContainer>
                </LoginFormRight>
              </Box>
            )}
          </>
        )}
      </LoginContainer>
      <SnackBar />
    </>
  );
};
export default Login;
