import { Paper, Typography, Box } from "@mui/material";
import "./MetaInfo.css";
import PatientInfo from "./PatientInfo";
import LabInfo from "./LabInfo";
import styled from "styled-components";
import ExtraStudyInfo from "./ExtraStudyInfo";
import { ShimmerText } from "react-shimmer-effects";

const HrLine = styled.div`
  border: 1px solid #c5dde0;
  margin: 1rem 0;
`;

const Color = {
  color: { xs: "#0099CC", sm: "#FFF" },
  fontSize: "16px",
  fontWeight: 600,
};

const MetaInfo = ({
  header = "File Information",
  recordInfo,
  scans,
  loading,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        background: "#D7EFF7",
        borderRadius: { xs: 0, sm: "0 1rem 1rem 0" },
        paddingBottom: 2,
        marginTop: { sm: 2, xs: 0 },
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{
          borderTopRightRadius: { xs: 0, sm: "1rem" },
          background: { xs: "#D7EFF7", sm: "#0099CC" },
          padding: "1.25rem",
        }}
      >
        <Typography sx={Color}>{header}</Typography>
      </Box>
      <Box m={2} className="record-wrap">
        {!loading ? (
          recordInfo ? (
            <>
              <PatientInfo recordInfo={recordInfo?.study_info} />
              <HrLine />
              <LabInfo recordInfo={recordInfo} />
              <HrLine />
              <ExtraStudyInfo
                recordInfo={recordInfo?.study_info}
                scans={scans}
              />
            </>
          ) : null
        ) : (
          <Box m={2} width={200}>
            <ShimmerText line={10} gap={30} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default MetaInfo;
