import moment from "moment";
import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getRecordType = async (patient_id) => {
  try {
    const url = `${API_ENDPOINT.RECORD_TYPES}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getRecords = async ({ record_type, patient_id, limit=10, page_token }) => {
  try {
    const url = page_token
      ? `${API_ENDPOINT.GET_RECORDS}/${record_type}?patient_id=${patient_id}&limit=${limit}&page_token=${page_token}`
      :  `${API_ENDPOINT.GET_RECORDS}/${record_type}?patient_id=${patient_id}&limit=${limit}`;
    const response = await apiClient.get(url);
    return {...response, record_type, patient_id};
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getChartRecords = async (record_type, patient_id) => {
  try {
    const startDate = moment("2021-01-01").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    const url = `${API_ENDPOINT.GET_Charts}/${record_type}/chart?patient_id=${patient_id}&start_date=${startDate}&end_date=${endDate}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getDicomFile = async ({ patient_id, study_id, series_id }) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/${patient_id}/scan/study/${study_id}/series/${series_id}/view`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getStudyList = async (patient_id) => {
  try {
    const url = `${API_ENDPOINT.GET_STUDY_LIST}`;
    // const url = `https://dev.hemehealth.app/app/v1/shared-view/patients/type/scans`;
    const response = await apiClient.post(url, { patient_id });
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getStudyDetail = async ({ fhir_id }) => {
  try {
    const url = `${API_ENDPOINT.GET_STUDY_DETAIL}?study_fhir_id=${fhir_id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};


 const getThumbnail =  async ({ file_objects}) => {
  try {
    const url = `${API_ENDPOINT.GET_THUMBNAIL}`;
    const response = await apiClient.post(url, { file_objects: file_objects});
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getReportUrl =  async ({ payload }) => {
  try {
    const url = `${API_ENDPOINT.GET_RECORD_URL}`;
    const response = await apiClient.post(url, payload);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};


const getScanDetails = async ({ fhir_id }) => {
  try {
    const url = `${API_ENDPOINT.GET_SCANS}/${fhir_id}/series/list`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getViewerUrl = async ({ payload }) => {
  try {
    const url = `${API_ENDPOINT.GET_VIEWER_URLS}`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getAttachedReports = async ({ fhir_id, patient_id }) => {
  try {
    const url = `${API_ENDPOINT.GET_ATTACHED_REPORT}/${fhir_id}/report?patient_id=${patient_id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export {
  getChartRecords,
  getDicomFile,
  getRecordType,
  getRecords,
  getStudyList,
  getStudyDetail,
  getThumbnail,
  getReportUrl,
  getScanDetails,
  getViewerUrl,
  getAttachedReports,
};
