import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import {
  DEVICE,
} from "../../../utilities/constants";

export const SideNavbar = styled.div`
  width: 260px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  height: calc(10px + 100vh);
  padding: 1rem;
  border-radius: 1rem;
  margin: 0px 0 0 48px;
  @media ${DEVICE.tablet} {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
  }
`;

export const MobileSideNavbar = styled.div`
  display: none;
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  opacity: 1;
  padding: 1rem;
  @media ${DEVICE.tablet} {
    display: ${(props) => (props?.showNav ? "flex" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    flex-direction: column;
    z-index:1 !important;
    width: 240px;
  }
`;

export const NavLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  height: 47px;
  line-height: 47px;
  color: #0099cc;
  text-decoration: none;
  padding-left: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

  &.active {
    background-color: #f5fbfc;
    border-radius: 8px 0px 0px 8px;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #0099CC;

  padding-top: 24px;
  justify-content: space-between;
`;

export const TopHeaderBackContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const TopHeaderCloseContainer = styled.div`
  flex: 0.3;
  justify-content: center;
  align-items: center;
`;

export const ArrowBackImage = styled.img``;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #000000;
`;
export const ProfileIcon = styled.img`
  margin: 24px;
`;
export const PhoneNumber = styled.div`
  font-size: 14px;
`;
export const SidebarImage = styled.img`
  margin-right: 10px;
`;
export const PatientName = styled.div`
  color: #0099cc;
  font-size: 16px;
  margin-bottom: 7px;
  word-break: break-all; 
`;
export const ProfileInfoContainer = styled.div`
  align-items: center;
  margin-left: 24px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  color: #808080;
  gap: 8px;
  font-size: 14px;
`;

export const MobileLogoutButton = styled.button`
  display: flex;
  flex-direction: row;
  width: 200px;
  align-self: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
`;

export const PatientEmail = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  width: 170px;
  margin-bottom: 5px;
`;

export const EmailText = styled.div`
    font-size: 14px;
    color: #000000;
    max-width: 170px;   
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;    
    word-break: break-all;
`;

export const EmailItem = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  margin-top: 7px;
`;

export const EmailImage = styled.img`
  height: 14px;
  margin-top: 5px;
`;