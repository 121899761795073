import React, { useState } from "react";
import { Box } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { formatTimestamp } from "../../../utilities/common";
import CloseIcon from "@mui/icons-material/Close";

const NoteRecordItem = ({ row, index }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleBoxClick = () => {
    setModalContent(row?.components[0]?.value);
    setModalOpen(true);
  };

  return (
    <div key={`notes-${index}`}>
      <Box onClick={handleBoxClick}>
        <Box
          sx={{
            width: 150,
            height: 190,
            background: "#FFF5C2",
            padding: "20px",
            borderRadius: "10px 10px 0 0",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <Box
            component="span"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 8,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              lineHeight: "1.6",
              fontSize: "14px",
              wordWrap: "break-word",
            }}
          >
            "{row?.components[0]?.value}"
          </Box>
        </Box>
        <Box
          sx={{
            width: 150,
            fontSize: "12px",
            background: "#2FAED7",
            padding: "8px 20px",
            color: "#FFF",
            borderRadius: "0 0 10px 10px",
          }}
        >
          {row?.upload_date && formatTimestamp(row.upload_date)}
        </Box>
      </Box>
      <CustomModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={modalContent}
      />
    </div>
  );
};

const CustomModal = ({ open, onClose, content }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "80%",
          maxWidth: "600px",
          height: "400px",
          minHeight: "200px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Notes</span>
        <IconButton onClick={onClose} sx={{ color: "gray" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          border: "1px solid #FFCF66",
          borderRadius: "5px",
          background: "#FFF5C2",
          margin: 2,
        }}
      >
        <p style={{ wordWrap: "break-word" }}>{content}</p>
      </DialogContent>
    </Dialog>
  );
};

export default NoteRecordItem;
