import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDicomFile, getRecordType, getRecords } from "../../api";
import {
  getChartRecords,
  getStudyList,
  getStudyDetail,
  getThumbnail,
  getReportUrl,
  getScanDetails,
  getViewerUrl,
  getAttachedReports,
} from "../../api/records.api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const getRecordTypeAction = createAsyncThunk(
  "get-record-type",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching record type`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getRecordType(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching record type",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching record type"
      );
    }
  }
);

export const getRecordsAction = createAsyncThunk(
  "get-records",
  async ({ record_type, patient_id, limit, page_token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching records`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getRecords({ record_type, patient_id, limit, page_token });
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || `Error while fetching ${record_type}`,
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || `Error while fetching ${record_type}`
      );
    }
  }
);

export const getChartRecordsAction = createAsyncThunk(
  "get-chart-records",
  async ({ record_type, patient_id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching records`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getChartRecords(record_type, patient_id);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || `Error while fetching ${record_type}`,
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || `Error while fetching ${record_type}`
      );
    }
  }
);

export const getDicomFileAction = createAsyncThunk(
  "get-dicom-file",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching dicom file",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getDicomFile(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching dicom file",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching dicom file"
      );
    }
  }
);
export const getStudyListAction = createAsyncThunk(
  "get-study-list",
  async ({ patient_id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching study list",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getStudyList(patient_id);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching study list",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching study list"
      );
    }
  }
);

export const getStudyInfo = createAsyncThunk(
  "get_study_detail_page_info",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching study detail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getStudyDetail(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching study details",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching study details"
      );
    }
  }
);

export const getThumbnails = createAsyncThunk(
  "get_thumbanail_image",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching thumbnail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getThumbnail(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while get thumbnail",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while get thumbnail"
      );
    }
  }
);

export const getReportURL = createAsyncThunk(
  "get_report_url",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching report url",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getReportUrl(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while get report url",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while get report url"
      );
    }
  }
);

export const getScansDetails = createAsyncThunk(
  "get_scans_info",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching scans detail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getScanDetails(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching scans details",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching scans details"
      );
    }
  }
);

export const getViewerUrls = createAsyncThunk(
  "VIEW_URLS",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "sharing report",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getViewerUrl(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sharing report",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while sharing report"
      );
    }
  }
);


export const getAttachedReport = createAsyncThunk(
  "get_attached_report_info",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching attached report",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getAttachedReports(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching attached report",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while Fetching attached report"
      );
    }
  }
);